<template>
    <div v-if="this !== undefined">
        <div class="customer-info" v-if="this.order != null">
            <div class="OP-header">Bestellerinformation</div>
            <table>
                <tr><td class="label">Name</td><td>{{this.order["delivery-name"]}}</td></tr>
                <tr><td class="label">Strasse</td><td>{{this.order["delivery-street"]}} {{this.order["delivery-number"]}}</td></tr>
                <tr><td class="label">Ort</td><td>{{this.order["delivery-zip"]}} {{this.order["delivery-city"]}}</td></tr>
                <tr><td class="label">Zusatz</td><td>{{this.order["delivery-description"]}}</td></tr>
                <tr><td class="label">Telefon</td><td>{{this.order["delivery-phone-number"]}}</td></tr>
                <tr><td class="label">Email</td><td>{{this.order["delivery-email"]}}</td></tr>
            </table>
        </div>
        <div class="order-info" v-if="this.order != null">
            <div class="OP-header">Auftrag</div>
            <table>
                <tr><td class="label2">NTP-Nummer</td><td>{{this.order["order-id"]}}</td></tr>
                <tr><td class="label2">Marktplatz Nummer</td><td>{{this.order["vendor-order-id"]}}</td></tr>
            </table>
        </div>

        <div class="shipment-list" v-if="this.order != null">
            <div v-for="(shipment, index) in this.order['shipping-units']"
                v-bind:shipment="shipment"
                v-bind:key="'K'+this.componentkey + '--' + shipment['shipping-unit-id']"
            >
                <ShippingUnit
                    v-bind:shipment="shipment" 
                    v-bind:parcelCount="this.order['shipping-units'].length"
                    v-bind:parcelIndex="index"
                    @parcelmoved = "parcelmoved"
                />
            </div>
        </div>

        <button class="OP-final-button" v-on:click="handlebackbutton">Fertig</button>
    </div>
</template>

<script>

import { API } from '../API.js'
// import CustomerInfo from './CustomerInfo.vue'
import ShippingUnit from './ShippingUnit.vue'

export default {
    name: 'OrderPage',
    props: [ "orderid" ],
    components: { /* CustomerInfo  */ ShippingUnit },
    data() {
        return {
            order: null,
            componentkey: 0,
        }
    },
    methods: {
        handlebackbutton() {
            this.$emit("orderidchosen", 0);
        },
        parcelmoved() {
            this.reloadOrder();
        },
        reloadOrder() {
            API.transact("order/"+this.$props.orderid, (success, result) => {
                if (success && result.status == 0) {
                    this.order = result.result;
                    // console.log("order read from API:");
                    // console.log(this.order);
                    this.componentkey ++;
                }
            });
        }
    },
    mounted() {
        this.reloadOrder();
    }
}
</script>

<style>

.OP-header {
    font-weight: bold;
    font-size: 20px;
}

.customer-info { 
    background-color: yellow;
    margin-bottom: 20px;
}

.order-info {
    background-color: lightblue;
    margin-bottom: 20px;
}

td.label {
    font-weight: bold;
    width: 100px;
}

td.label2 {
    font-weight: bold;
    width: 200px;
}

.OP-final-button {
    width: 100%;
    height: 100px;
}

</style>
