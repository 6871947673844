<template>
    <div v-if="this !== undefined">
        <div class="SP-upload-csv">
            <div class="SP-section-header">
            Neue CSV-Bestandsdatei auswählen:
            </div>
            <input type="file" class="SP-upload-fileselect" accept=".csv" id="SP-upload-file-button-csv">
            <button class="SP-upload-button" v-on:click="handleuploadbutton(0)">Testen</button>
            <button class="SP-upload-button" v-on:click="handleuploadbutton(1)">Hochladen</button>
        </div>
        <div class="SP-upload-csv">
            <div class="SP-section-header">
            Neue XLSX-Bestandsdatei auswählen:
            </div>
            <input type="file" class="SP-upload-fileselect" accept=".xlsx" id="SP-upload-file-button-xlsx">
            <button class="SP-upload-button" v-on:click="handleuploadbutton(2)">Testen</button>
            <button class="SP-upload-button" v-on:click="handleuploadbutton(3)">Hochladen</button>
        </div>
        <div class="SP-upload-progress">
            <div class="SP-upload-percent" id="SP-upload-percent"></div>
            <div class="SP-upload-done" id="SP-upload-done"></div>
            <div class="SP-upload-needed" id="SP-upload-needed"></div>
            <div class="SP-upload-inactive" id="SP-upload-inactive"></div>
        </div>
        <div class="SP-upload-statistics" v-if="uploadresult !== null" v-on:click="uploadresult = null" >
            Neu: {{uploadresult.result.createdRows}}<br>
            Geändert: {{uploadresult.result.updatedRows}}<br>
            Gelöscht: {{uploadresult.result.deletedRows}}<br>
            Unlesbar: {{uploadresult.result.rejectedRows}}
        </div>
        <div class="SP-upload-error" v-if="uploaderror !== null && uploaderror !== ''" v-on:click="uploaderror = null">
            {{uploaderror}}
        </div>
        <div class="SP-section-separator"><hr></div>
        <div class="SP-download-csv">
            <div class="SP-section-header">
            Vorhandene Bestände downloaden:
            </div>
            <button class="SP-download-button" v-on:click="handledownloadbutton">Download</button>
        </div>
        <hr>
        <div class="SP-show-stock" v-if="stockinfo !== null">
            <div class="SP-section-header">
                Vorhandene Bestände
            </div>
           Filter: <input v-model="stockfilter" class="input" v-on:change="handlestockfilterchange">
           <div class="SP-stocktable-table"
                v-for="stockelement in matchfilter(stockinfo)"
                v-bind:stockelement="stockelement"
                v-bind:key="stockelement.country + stockelement.vendor + stockelement.ean"
            >
            <StockElement :stockelement="stockelement" />
           </div>
        </div>
    </div>
</template>

<script>
import { API } from '../API.js'
import StockElement from './StockElement.vue'

export default {
    name: "StockPage",
    data() {
        return {
            uploadresult: null,
            uploaderror: "",
            stockinfo: null,
            stockfilter: "",
        }
    },
    components: { StockElement },
    methods: {
        handleuploadbutton(flags) {
            const format = (flags & 2 ? "xlsx" : "csv");
            const button = document.getElementById("SP-upload-file-button-"+format);
            if (button.files.length > 0) {
                console.log("uploading "+button.files.[0].name);
                this.uploadingfile = button.files[0].name;
                this.uploadedpercent = 0;

                document.getElementById("SP-upload-done").style.width = "0%";
                document.getElementById("SP-upload-needed").style.width = "100%";
                document.getElementById("SP-upload-done").style.visibility = "visible";
                document.getElementById("SP-upload-needed").style.visibility = "visible";

                const reader = new FileReader();
                const xhr = new XMLHttpRequest();
                this.xhr = xhr;
                const self = this;

                this.xhr.upload.addEventListener("progress", function(e) {
                    if (e.lengthComputable) {
                        const percentage = e.loaded * 100 / e.total;
                        self.uploadedchunk(percentage);
                    }
                }, false);

                this.xhr.onreadystatechange = function() {
                    if (xhr.readyState === XMLHttpRequest.DONE) {
                        var status = xhr.status;
                        var error = null;
                        self.uploadedchunk(100);
                        if (status == 0 || status >= 200 && status < 300) {
                            if (xhr.response == null || xhr.response.length == 0) {
                                error = "Leere Antwort";
                            } else {
                                try {
                                    self.uploadresult = JSON.parse(xhr.response);
                                    if (self.uploadresult.status == 0) {
                                        self.uploaderror = null;
                                        self.$forceUpdate();
                                    } else {
                                        error = self.uploadresult.error;
                                        self.uploadresult = null;
                                    }
                                } catch (e) {
                                    error = "Ungültige Antwort";
                                }
                            }
                        } else {
                            error = "HTTP Fehler "+status;
                        }
                        if (error) {
                            console.log(status);
                            self.uploaderror = error;
                            self.uploadresult = null;
                            self.$forceUpdate();
                        }
                    }
                }

                const endpoint = ((flags & 1) ? "import" : "testimport");
                const content = ((flags & 2) ? "application/x-vnd.msexcel" : "text/csv;charset=utf-8");
                
                // Can't use API.transact() because we want to show the progress bar
                xhr.open("POST", API.url + "/product/"+endpoint+"/"+format+"?vendor=autodoc");   // TODO vendor must be selectable
                xhr.setRequestHeader("Api-Key", API.apikey);
                xhr.setRequestHeader("Content-type", content);
                reader.onload = function(e) {
                    console.log("sending "+e.target.result.length+" bytes");
                    xhr.send(e.target.result);
                }
                console.log("Endpoint:"+endpoint+", Format:"+format+", Content:"+content+", Flags"+flags);
                if (flags & 2) {    // excel
                    xhr.send(button.files[0]);
                    // reader.readAsBinaryString(button.files[0]);
                } else {
                    reader.readAsText(button.files[0], "utf-8");
                }
            }
        },
        uploadedchunk(val) {
            if (val >= 100) {
                document.getElementById("SP-upload-percent").innerHTML = "";
                document.getElementById("SP-upload-done").style.visibility = "hidden";
                document.getElementById("SP-upload-needed").style.visibility = "hidden";
            } else {
                document.getElementById("SP-upload-percent").innerHTML = val.toFixed(1) + "% "+this.uploadingfile;
                document.getElementById("SP-upload-done").style.width = val + "%";
                document.getElementById("SP-upload-needed").style.width = (100-val) + "%";
            }
        },
        handledownloadbutton() {
            // can't use API.transact as that wants a JSON response
            fetch(API.url+"/product/list/csv", {
                "headers": {
                    "Api-Key": API.apikey
                }
            })
            .then(response => response.text())
            .then(text => {
                const element=document.createElement("a");
                element.setAttribute("download", "Lagerbestand.csv");
                element.setAttribute('href', 'data:text/plain;charset=utf-8,'+encodeURIComponent(text));
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            });
        },
        downloadedcsv(status, result) {
            if (status == true) {
                console.log(result);
            }
        },
        listreceived(status, result) {
            if (status && result.status == 0) {
                console.log(result);
                this.stockinfo = result.result;
            }
        },
        matchfilter(stockinfo) {
            return stockinfo.filter(element => (
                this.stockfilter === null
                || this.stockfilter === ""
                || element['product-name'].toLowerCase().indexOf(this.stockfilter) !== -1
                || element['ean'].toLowerCase().indexOf(this.stockfilter) !== -1
            ));
        }
    },
    mounted() {
        API.transact("product/list", this.listreceived);
    }
}

</script>

<style>
.SP-upload-csv, .SP-download-csv, .SP-show-stock {
    background-color: lightblue;
}

.SP-section-header {
    height: 30px;
    display:flex;
    align-items: center;
    font-weight: bold;
}

.SP-section-separator {
    background-color: darkblue;
}

.SP-upload-progress {
    background-color: white;
    height: 20px;
    width: 100%;
}
.SP-upload-done {
    background-color: green;
    width: 3%;
    height: 20px;
    display:inline-block;
    visibility: hidden;
    /* opacity: 0.5; */
}
.SP-upload-needed {
    background-color: red;
    width: 97%;
    height: 20px;
    display:inline-block;
    visibility: hidden;
    /* opacity: 0.5; */
}

.SP-upload-percent {
    position: absolute;
    height: 20px;
    display: flex;
    margin-left: 10px;
    z-index: 1;
}

.SP-upload-statistics {
    background-color: cyan;
}

.SP-upload-error {
    background-color: cyan;
    color: red;
}

.SP-stocktable-table:nth-child(even) { background-color: aqua; }
.SP-stocktable-table:nth-child(odd) { background-color: skybluedf -; }


</style>