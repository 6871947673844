<template>
    <div class="SP-stocktable-row">
        <div class="SP-stocktable-row1">
            <div class="SP-stocktable-vendor">{{stockelement.vendor}}</div>
            <div class="SP-stocktable-country">{{stockelement.country}}</div>
        </div>
        <div class="SP-stocktable-row2">
            <div class="SP-stocktable-ean">{{stockelement.ean}}</div>
            <div class="SP-stocktable-stock">
                <input v-model="amount" v-on:change="handleamountchanged" class="SP-stocktable-amount">
            </div>
        </div>
        <div class="SP-stocktable-row3">
            <div class="SP-stocktable-product-name">{{stockelement['product-name']}}</div>
        </div>
    </div>
</template>

<script>
import { API } from '../API.js'

export default {
    name: "StockElement",
    props: [ 'stockelement' ],
    data() {
        return {
            amount: 0,
        }
    },
    methods: {
        handleamountchanged() {
            var url = "product/setstock/"+this.stockelement['ean']+"?amount="+this.amount;
            url += "&vendor="+this.stockelement.vendor;
            url += "&country="+this.stockelement.country;
            console.log(url);
            API.transact(url, this.updatedamount);
        },
        updatedamount(status, result) {
            if (status && result.status == 0) {
                console.log(result);
            } else {
                console.log('Update result: '+status);
                this.amount = this.stockelement['amount-in-stock'];
            }
        }
    },
    mounted() {
        this.amount = this.stockelement['amount-in-stock']
    }
}
</script>

<style>

.SP-stocktable-row {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}

.SP-stocktable-row1, .SP-stocktable-row2 {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}

.SP-stocktable-amount {
    width: 30px;
}

</style>