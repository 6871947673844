<template>
<div class="label errormessage" :class="vis" id="login-error-msg">{{message}}</div>
</template>

<script>
export default {
    name: 'ErrorMessage',
    props: [ 'message', 'vis' ]
}
</script>

<style scoped>
.errormessage {
    color: red;
}

.visible { visibility: visible; }
.hidden { visibility: hidden; }
</style>