<template>
    <div class="order-all">
        <div class="order-select">
            <div class="order-type">Bestellungen: unbearbeitet</div>
            <div class="order-icon">Bild</div>
        </div>
        <div class="order-list">
            <OrderMeta
                @orderidchosen="orderidchosen"
                v-for="order in workingorders"
                v-bind:order="order"
                v-bind:key="order.id"
            />
        </div>

        <div class="order-select" v-if="cancelledorders.length > 0">
            <div class="order-type">Bestellungen: storniert</div>
            <div class="order-icon">Bild</div>
        </div>
        <div class="order-list">
            <OrderMeta
                @orderidchosen="orderidchosen"
                v-for="order in cancelledorders"
                v-bind:order="order"
                v-bind:key="order.id"
            />
        </div>

        <div class="order-select" v-if="shippedorders.length > 0">
            <div class="order-type">Bestellungen: versandt</div>
            <div class="order-icon">Bild</div>
        </div>
        <div class="order-list">
            <OrderMeta
                @orderidchosen="orderidchosen"
                v-for="order in shippedorders"
                v-bind:order="order"
                v-bind:key="order.id"
            />
        </div>

        <div class="order-select" v-if="arrivedorders.length > 0">
            <div class="order-type">Bestellungen: angekommen</div>
            <div class="order-icon">Bild</div>
        </div>
        <div class="order-list">
            <OrderMeta
                @orderidchosen="orderidchosen"
                v-for="order in arrivedorders"
                v-bind:order="order"
                v-bind:key="order.id"
            />
        </div>

    </div>
</template>

<script>

import { API } from '../API.js'
import OrderMeta from './OrderMeta.vue';

export default {
    components: { OrderMeta },
    name: 'OrderFinder',
    data() {
        return {
            workingorders: [],
            cancelledorders: [],
            shippedorders: [],
            arrivedorders: []
        }
    },
    methods: {
        orderidchosen(orderid) {
            // console.log("order id chosen in OrderFinder");
            this.$emit("orderidchosen", orderid);
        }
    },
    mounted()  {
        // console.log('Component is mounted!');
        API.transact("order/list", (success, result) => {
            if (success && result.status == 0) {

                this.workingorders = [];
                this.cancelledorders = [];
                this.shippedorders = [];
                this.arrivedorders = [];
                for (const order of result.result) {
                    let arrived = false;
                    let shipped = false;
                    let cancelled = false;
                    let assembling = false;
                    // console.log(order['order-id']+": ");
                    for (const shippingunit of order['shipping-units']) {
                        // console.log("   "+shippingunit['shipping-stage']);
                        if (shippingunit['shipping-stage'] == 'CANCELLED'  || shippingunit['shipping-stage'] == "RETURNED") {
                            cancelled = true;
                        } else if (shippingunit['shipping-stage'] == 'SHIPPED' || shippingunit['shipping-stage'] == "LOST") {
                            shipped = true;
                        } else if (shippingunit['shipping-stage'] == 'IN_ASSEMBLY') {
                            // console.log("found in assembly");
                            assembling = true;
                        } else if (shippingunit['shipping-stage'] == 'DELIVERED') {
                            arrived = true;
                        }
                    }

                    if (assembling) {
                        this.workingorders.push(order);
                    } else if (cancelled) {
                        this.cancelledorders.push(order);
                    } else if (shipped) {
                        this.shippedorders.push(order);
                    } else if (arrived) {
                        this.arrivedorders.push(order);
                    }
                }

                // this.workingorders = result.result;
            }
        });
    }
}
</script>

<style>
.order-select {
    display: flex;
    height: 60px;
    background-color: #00cc00;
}

.order-type {
    display: flex;
    align-items: center;
    padding-left: 5px;
    width: 270px;
    flex-grow: 1;
    background-color: #00ee00;
}

.order-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    flex-grow: 0;
    background-color: #00aa00;
}

</style>
