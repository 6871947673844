/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

export class ShippingStageEnum {
    static stagename(stage) {
        if (stage === 'IN_ASSEMBLY')        return "wird verpackt";
        if (stage === 'SHIPPED')            return "versandt";
        if (stage === 'CANCELLED')          return "storniert";
        if (stage === 'DELIVERED')          return "ausgeliefert";
        if (stage === 'RETURNED')           return "zurückgesendet";
        if (stage === 'LOST')               return "verloren";
        return "? "+ stage;
    }
    
    static stages = [
        "IN_ASSEMBLY", "SHIPPED", "CANCELLED", "DELIVERED", "RETURNED", "LOST"
    ];
}