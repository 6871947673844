export class API {
    
    // overwritten in setHost, called from LoginPage before login
    static url = 'https://test-api.new-tech-products.com/api/v1';
    
    static apikey = '';
    
    static login(user, password, callback) {
        // fetch(API.url+"/user/verify/"+user, {
        fetch(API.url+"/user/list", {
            headers: {
                "Api-Key": user+":"+password
            }
        })
        .then(response => response.json())
        .then(result => {
            if (result.status == 0) {
                callback(true, result);
            } else {
                callback(false, result);
            }
        })
        .catch(error => {
            callback(false, error);
        })
        ;
    }
    
    static transact(url, callback) {
        fetch(API.url+"/"+url, {
            headers: {
                "Api-Key": API.apikey
            }
        })
        .then(response => response.json())
        .then(result => {
            if (result.status == 0) {
                callback(true, result);
            } else {
                callback(false, result);
            }
        })
        .catch(error => {
            callback(false, error);
        })
        
    }
    
    static setApikey(newkey) {
        API.apikey = newkey;
    }
    
    static setHost(newHost) {
        API.url="https://"+newHost+"/api/v1";
    }
}