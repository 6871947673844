<template>
    <div class="UP-email">
        <div class="UP-section-header">
            Email:
        </div>
        <div>
            <input v-model="email" v-on:change="handleemailchanged" type="text" autocomplete="email" class="UP-inputfield">
        </div>
        <div>
            <input v-model="getmails" v-on:change="handlegetmailschanged" type="checkbox" class="UP-getemail">
            Mails bei neuen Bestellungen erhalten?
        </div>
    </div>
    <div class="UP-section-separator"><hr></div>
    <div class="UP-password">
        <div class="UP-section-header">
            Passwort ändern:
        </div>
        <form>
        <div class="UP-password-row">
            <div class="UP-password-title">altes PW:</div>
            <div class="UP-password-entry"><input v-model="oldpw" type="password" autocomplete="current-password" class="UP-inputfield"></div>
        </div>

        <div class="UP-password-row">
            <div class="UP-password-title">neues PW:</div>
            <div class="UP-password-entry"><input v-model="newpw" type="password" autocomplete="new-password" v-on:input="newpwchanged" class="UP-inputfield"></div>
        </div>

        <div class="UP-password-row">
            <div class="UP-password-title">Kontrolle:</div>
            <div class="UP-password-entry"><input v-model="newpw2" type="password" autocomplete="new-password" v-on:input="newpwchanged" class="UP-inputfield"></div>
        </div>
        <div class="UP-password-row">
            <div class="UP-password-title"></div>
            <div class="UP-password-message" id="UP-password-message"></div>
        </div>
        <button class="button-primary" id="UP-password-changebutton" v-on:click="changepassword">Ändern</button>
        </form>
    </div>
</template>

<script>
import { API } from '../API.js'

export default {
    name: "UserPage",
    data() {
        return {
            email: null,
            getmails: null,
            oldpw: null,
            newpw: null,
            newpw2: null,
            browserAutoCompleteDone: false
        }
    },
    methods: {
        handleemailchanged() {
            if (this.browserAutoCompleteDone) {
                API.transact("user/params?email="+this.email, this.transactiondone);
            }
        },
        handlegetmailschanged() {
            API.transact("user/params?wantOrderMails="+(this.getmails ? "true" : "false"), this.transactiondone);
        },
        transactiondone(status, result) {
            console.log(result);
            if (result.status == 0) {
                this.browserAutoCompleteDone = false;
                var parts = result.result.split(",");
                this.email = parts[0];
                this.getmails = (parts[1] === "true");
                this.browserAutoCompleteDone = true;
            }
        },
        passwordproblem(problem) {
            const element = document.getElementById("UP-password-message");
            element.innerHTML = problem;
            if (this.problemtimeout != null) {
                clearTimeout(this.problemtimeout);
            }
            this.problemtimeout = setTimeout(this.clearpasswordproblem, 3000);
        },
        clearpasswordproblem() {
            const element = document.getElementById("UP-password-message");
            element.innerHTML = '';
            this.problemtimeout = null;
        },
        changepassword() {
            if (this.checknewpasswords()) {
                API.transact("user/setpassword?oldPassword="+this.oldpw+"&newPassword="+this.newpw, this.pwchangeresult);
            }
        },
        pwchangeresult(status, result) {
            if (result.status == 0) {
                this.passwordproblem("Das Passwort wurde geändert");
                this.$emit("gotkey", "");   // this doesn't work ..
                setTimeout(function() { location.reload() }, 2);
            } else {
                this.passwordproblem("Server meldet: "+result.error);
            }
        },
        newpwchanged() {
            const button = document.getElementById("UP-password-changebutton");
            if (this.checknewpasswords()) {
                button.disabled = false;
            } else {
                button.disabled = true;
            }
        },
        checknewpasswords() {
            if (this.newpw !== this.newpw2) {
                this.passwordproblem("neue Passworte verschieden");
                return false;
            } else {
                this.passwordproblem("");
                return true;
            }
        }
    },
    mounted() {
        API.transact("user/params", this.transactiondone);
    }
}

</script>

<style>
.UP-email, .UP-password {
    background-color: lightgreen;
}

.UP-section-header {
    height: 30px;
    display:flex;
    align-items: center;
    font-weight: bold;
}

.UP-section-separator {
    background-color: darkblue;
}

.UP-inputfield {
    background-color: white;
}

.UP-password-table {
    display: flex;
    flex-direction: column;
}

.UP-password-row {
    display: flex;
    flex-direction: row;
    margin-top: 2px;
}

.UP-password-title {
    width: 130px;
}

.UP-password-message {
    color: red;
}
</style>
