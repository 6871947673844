<template>
    <div class="SU-shipment-header">
        <div class="SU-parcel-id"> Paket {{parcelIndex + 1}} von {{parcelCount}} </div>
        <div class="SU-tracking-info">
            <div class="SU-shipment-shipper">
                <select v-model="selectedshipper" v-on:change="handleshipperselect">
                <option value="???">???</option>
                <option v-for="shipper in shippers" v-bind:key="shipper" v-bind:value="shipper">
                    {{shipper}}
                </option>
                </select>
            </div>
            <div class="SU-shipment-tracking">
                <input v-model="tracking" class="input" placeholder="00-000000" v-on:change="handletrackingchange">
            </div>
            <div class="SU-shipment-statechange">
                {{friendlydate(selectedstagechange)}}
            </div>
            <div class="SU-shipment-state">
                <select v-model="selectedstage" v-on:change="handlestageselect">
                <option v-for="stage in stages" v-bind:key="stage" v-bind:value="stage">
                    {{ stagename(stage) }}
                </option>
                </select>
            </div>
        </div>
    </div>
    <div class="SU-product-list">
        <div class="SU-product"
            v-for="(product, productIndex) in shipment['ordered-products']"
            v-bind:product="product"
            v-bind:key="product['product-order-id']"
        >
            <div class="SU-product-lr">
                <div class="SU-product-td">
                    <div class="SU-product-desc">{{ product["product-description"] }}</div>
                    <div class="SU-product-ean"> {{ product["ean"] }} </div>
                </div>
                <div class="SU-product-count">Anzahl<br>{{ product["ordered-amount"] }} </div>
                <div class="SU-product-parcelno">
                    <div>Paket</div>
                    <div>
                        <select v-bind:product-order-id="product['product-order-id']" v-model="parcelIndexesOneBased[productIndex]" v-on:change="handleparcelselect">
                        <option v-for="index in parcelCount+1" v-bind:key="index" v-bind:value="index">{{index}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

import {Config} from '../Config.js'
import {API} from '../API.js'
import { ShippingStageEnum } from '../ShippingStageEnum.js'

export default {
    name: "ShippingUnit",
    props: {
        shipment: { type: Object }, 
        parcelCount: { type: Number },
        parcelIndex: { type: Number }
    },
    emits: ['parcelmoved'],
    data() {
        return {
            selectedshipper: '',
            parcelIndexesOneBased: [],
            tracking: '',
            shippers: Config.shippers,
            selectedstagechange: '',
            selectedstage: '',
            stages: ShippingStageEnum.stages,
            preventRecursion: false
        }
    },
    methods: {
        apipath() {
            return "order/update/"+this.shipment["order-id"]+"/unit/"+this.shipment["shipping-unit-id"];
        },
        handleshipperselect() {
            // console.log(this.selectedshipper, this.shipment["shipping-unit-id"]);
            API.transact(this.apipath()+"?service="+this.selectedshipper,
                this.apiresult);
        },
        handletrackingchange() {
            // console.log(this.tracking, this.shipment["tracking"]);
            API.transact(this.apipath()+"?tracking="+this.tracking,
                this.apiresult);
        },
        handleparcelselect(event) {
            // console.log(event);
            let select = event.target;
            //console.log("select is ", select);
            //console.log("ordered product id is ", select.attributes['product-order-id'].value);
            //console.log("option index is ", select.selectedIndex);
            //console.log("new parcel number is ", select.options[select.selectedIndex].value);
            API.transact(this.apipath()+"?moveproduct="+select.attributes['product-order-id'].value+"&parcelnumber="+select.options[select.selectedIndex].value,
                this.tellparentaboutmovedparcel);
        },
        tellparentaboutmovedparcel() {
            // console.log("we should reload now");
            this.$emit("parcelmoved");
        },
        handlestageselect(event) {
            let select = event.target;
            // console.log("new index is ", select.selectedIndex);
            // console.log("new stage is ", select.options[select.selectedIndex].value);
            API.transact(this.apipath()+"?stage="+select.options[select.selectedIndex].value, this.stageselected);
        },
        // eslint-disable-next-line no-unused-vars
        stageselected(success, result) {
            console.log(success, result);
            if (success) {
                // This is cheating, but as the result is the whole order, it'd
                // be quite hard to find out which of the shipping units we are.
                this.selectedstagechange = Date.now();
            }
        },
        // eslint-disable-next-line no-unused-vars
        apiresult(success, result) {
        //  console.log(success, result);
        },
        // eslint-disable-next-line no-unused-vars
        createParcelIndexes(why) {
            this.selectedshipper = this.$props.shipment.service;
            this.selectedstagechange = this.$props.shipment['shipping-stage-change'];
            this.selectedstage = this.$props.shipment['shipping-stage'];
            this.tracking = this.$props.shipment.tracking;
            this.parcelIndexesOneBased = [];
            for (let i=0; i<this.$props.shipment['ordered-products'].length; i++) {
                this.parcelIndexesOneBased[i] = this.$props.parcelIndex + 1;
            }
        //  console.log(why+"  shippingunit, has "+this.$props.shipment['ordered-products'].length+" shipments, indexes are "+this.parcelIndexesOneBased);
        },
        stagename(stage) {
            return ShippingStageEnum.stagename(stage);
        },
        friendlydate(currentTimeMillis) {
            if (currentTimeMillis == 0) {
                return "";
            }
            const date = new Date(currentTimeMillis);
            const options = { day:'numeric', month: 'numeric', hour: 'numeric', minute:'numeric' };
            return date.toLocaleString("de", options);
        }
    },
    mounted() {
        this.createParcelIndexes("mounted");
    }
}
</script>

<style scoped>

.SU-shipment-header {
    display: flex;
    flex-direction: column;
    background-color: cyan;
    margin-left: 20px;
}

.SU-parcel-id {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    font-weight: bold;
    height: 25px;
    margin-top: 3px; margin-bottom: 3px;
}

.SU-tracking-info {
    display: flex;
    height: 25px;
    margin-top: 3px; margin-bottom: 3px;
}

.SU-shipment-shipper {
    display: flex;
    align-items: center;
    width: 60px;
    flex-grow: 0;
}

.SU-shipment-tracking {
    display: flex;
    align-items: center;
    width: 120px;
    flex-grow: 1;
}

.SU-shipment-statechange {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 120px;
    flex-grow: 0;
}

.SU-shipment-state {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 120px;
    flex-grow: 0;
}

.SU-product-list {
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    background-color: lightgreen;
    margin-bottom: 20px;
}

.SU-product-lr {
    display: flex;
    flex-direction: row;
    background-color: #0080cc;
    height: 60px;
}

.SU-product-td {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #00cc80;
    height: 60px;
    flex-grow: 1;
    min-width: 10px;
}

.SU-product-desc {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    align-items: center;
    display: flex;
    flex-grow: 1;
    min-width: 10px;
    height: 30px;
}

.SU-product-ean {
    align-items: center;
    display: flex;
    flex-grow: 1;
    height: 30px;
}

.SU-product-parcelno, .SU-product-count {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #20cccc;
    width: 60px;
    min-width: 60px;
    height: 60px;
    flex-grow: 0;
}


</style>
