<template>
  <!--img alt="Vue logo" src="./assets/logo.png"-->
  <!--HelloWorld msg="Welcome to Your Vue.js App"/-->
  <LoginPage @gotkey="gotkey" v-if="loginpage" v-bind:apikey="apikey"/>
  <MainScreen v-if="mainscreen"/>
</template>

<script>
import { API } from './API.js'
import LoginPage from './components/LoginPage.vue'
import MainScreen from './components/MainScreen.vue'

export default {
  name: 'App',
  components: {
    LoginPage,
    MainScreen
  },
  data() {
    return {
      loginpage: true,
      mainscreen: false,
      apikey: "secret",
    }
  },
  methods: {
    gotkey(apikey) {
      this.apikey = apikey;
      // console.log("set apikey in main to "+this.apikey);
      API.setApikey(apikey);
      this.loginpage = (this.apikey === "");
      this.mainscreen  = (this.apikey !== "");
    }
  }
}
</script>

<style>
#XXapp {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
