<template>
    <div class="mainscreen-frame">
        <Header @menuitemchosen="menuitemchosen" v-bind:menuitem = "menuitem"/>
        <OrderFinder
            v-if="this.menuitem == 0 && this.orderid === 0"
            @orderidchosen="orderidchosen"
        />
        <OrderPage
            v-if="this.menuitem == 0 && this.orderid !== 0"
            v-bind:orderid="orderid"
            @orderidchosen="orderidchosen"
        />
        <StockPage
            v-if="this.menuitem == 1"
        />
        <UserPage
            v-if="this.menuitem == 2"
        />
  </div>
</template>

<script>

import Header from './Header.vue';
import OrderFinder from './OrderFinder.vue';
import OrderPage   from './OrderPage.vue';
import StockPage from './StockPage.vue';
import UserPage from './UserPage.vue';

export default {
    name: 'MainScreen',
    components: { Header, OrderFinder, OrderPage, StockPage, UserPage },
    data() {
        return {
            menuitem: 0,
            orderid: 0
        }
    },
    methods: {
        orderidchosen(orderid) {
            this.orderid = orderid;
        },
        menuitemchosen(menuid) {
            this.menuitem = menuid;
        }
    }
}
</script>

<style>
.mainscreen-frame { 
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 35px;
    background-color: purple;
}
</style>
