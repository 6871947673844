<template>
    <div class="header">
        <div class="header-logo"> N.T.P. </div>
        <div class="header-vendor">AutoDoc</div>
        <div class="header-country">DE</div>
    </div>
    <div class="menu">
        <div v-bind:class="this.isMenuItemSelected(0)" v-on:click="menuselect(0)">Bestellungen</div>
        <div v-bind:class="this.isMenuItemSelected(1)" v-on:click="menuselect(1)">Lagerbestände</div>
        <div v-bind:class="this.isMenuItemSelected(2)" v-on:click="menuselect(2)">Benutzereinstellungen</div>
    </div>

</template>

<script>
export default {
    name: 'Header',
    props: [ "menuitem" ],
    emits: ['menuitemchosen'],
    methods: {
        isMenuItemSelected(id) {
            return (this.menuitem == id ? "menuitem menuitem-selected" : "menuitem menuitem-unselected");
        },
        menuselect(item) {
            this.$emit("menuitemchosen", item);
        }
    }
}
</script>

<style>
.header {
    height: 60px;
    background-color: pink;
    display: flex;
    /* margin-bottom: 20px; */
}

.header-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 60px;
    flex-grow: 0;
    background-color: #0000ff;
    color: #ffffff;
}

.header-vendor {
    display: flex;
    align-items: center;
    padding-left: 5px;
    width: 160px;
    height: 60px;
    flex-grow: 1;
    background-color: #0000aa;
    color: #ffffff;
}

.header-country {
    display: flex;
    align-items: center;
    padding-left: 5px;
    width: 55px;
    height: 60px;
    flex-grow: 0;
    background-color: #0000cc;
    color: #ffffff;
}

.menu {
    display: flex;
    align-items: center;
    width: 100%;
    height:60px;
    flex-grow: 0;
    background-color: #e8e8e8;
    color: #000000;
}

.menuitem {
    /* width: 130px; */
    height: 45px;
    border: 3px solid black;
    border-radius: 5px;
    margin-left: 3px;
    margin-right: 3px;
    padding-left: 3px;
    padding-right: 3px;
    display: flex;
    align-items: center;
}

.menuitem-selected {
    color: #000000;
}

.menuitem-unselected {
    color: #808080;
}

</style>
