<template>
    <div class="loginbox">
        <div class="spacer"></div>
        <div class="header">Mirakl Verwaltung</div>
        <form>
        <div class="label">Username</div>
        <input v-model="username" class="input" placeholder="" id="username" autocomplete="username">
        <div class="label">Password</div>
        <input v-model="password" class="input" type="password" placeholder="" id="password" autocomplete="current-password">
        </form>
        <ErrorMessage :message=lastError :vis=hideErrorMessage />
        <button class="button-primary" v-on:click="handleclick">Log in</button>
    </div>
</template>

<script>

import { API } from '../API.js'
import ErrorMessage from './ErrorMessage.vue'

export default {
    name: 'LoginPage',
    data() {
      return {
        username: '',
        password: '',
        lastError: 'Login Error',
        hideErrorMessage: 'hidden'
      }
    },
    components: {
        ErrorMessage
    },
    methods: {
        handleclick() {
            // console.log(window.location.hostname);
            var apiHost = window.location.hostname;
            if (apiHost == "localhost") {
                apiHost="test-api.new-tech-products.com";
            }
            API.setHost(apiHost);
            API.login(this.username, this.password, (success, result) => {
                this.ignore(result);
                if (success) {
                    this.$emit("gotkey", this.username+":"+this.password);
                } else {
                    this.lastError = "Login error: ";
                    this.hideErrorMessage = 'visible';
                    setTimeout ( () => { this.hideErrorMessage = 'hidden'; } , 2000);
                }
            });
            
        },
/*eslint-disable no-unused-vars*/
        ignore(x) { },
/*eslint-enable no-unused-vars*/
    }
}
</script>

<style scoped>
.root {
    margin-top: auto;
    margin-bottom: auto;
}

.spacer {
    height: 200px;
}

.loginbox {
    margin-left: 48px;
    width: 300px;
}

.header {
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 20px;
}

.label {
    font-size: 14px;
}

.input {
    margin-top: 10px;
    font-size: 14px;
    margin-bottom: 20px;
}

.button-primary {
    display: block;
    margin-top: 30px;
    width: 100px;
    height: 25px;
    text-align: center;
    vertical-align: middle;
}
</style>
