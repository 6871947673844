<template>
    <div class="order" v-on:click="orderclicked" >
        <div class="order-number">NTP Bestellung {{order["order-id"]}} / {{order["ordering-user"].vendor}} {{order["vendor-order-id"]}} / {{order['delivery-name']}}</div>
        <div class="order-shipment"
            v-for="(shipment, index) in order['shipping-units']"
            v-bind:shipment="shipment"
            v-bind:key="shipment['shipping-unit-id']"
        >
            <div class="shipment-index">{{index}}</div>
            <div class="shipment-provider">{{shipment["service"] || "???"}}</div>
            <div class="shipment-tracking">{{shipment["tracking"] || "00-00000"}}</div>
            <div class="shipment-statechange">{{friendlydate(shipment["shipping-stage-change"])}}</div>
            <div class="shipment-state">{{stagename(shipment["shipping-stage"]) || "?"}}</div>
        </div>
    </div>
</template>

<script>

import { ShippingStageEnum } from '../ShippingStageEnum.js'

export default {
    name: "OrderMeta",
    props: [ "order" ],
    methods: {
        orderclicked() {
            // console.log("order clicked");
            this.$emit("orderidchosen", this.$props.order["order-id"]);
        },
        stagename(stage) {
            return ShippingStageEnum.stagename(stage);
        },
        friendlydate(currentTimeMillis) {
            if (currentTimeMillis == 0) {
                return "";
            }
            const date = new Date(currentTimeMillis);
            const options = { day:'numeric', month: 'numeric', hour: 'numeric', minute:'numeric' };
            return date.toLocaleString("de", options);
        }
    }
}
</script>

<style>
.order {
    display:flex;
    flex-direction: column;
    background-color: #ffff00;
}

.order-number {
    display: flex;
    align-items: center;
    margin-top: 3px;
    margin-bottom: 3px;
    padding-left: 5px;
    height: 30px;
    background-color: #ffdd00;
}

.order-shipment {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 25px;
    background-color: #ffbb00;
}

.shipment-index {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    flex-grow: 0;
}

.shipment-provider {
    display: flex;
    align-items: center;
    width: 40px;
    flex-grow: 0;
}

.shipment-tracking {
    display: flex;
    align-items: center;
    width: 120px;
    flex-grow: 1;
}

.shipment-statechange {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 150px;
    flex-grow: 0;
}

.shipment-state {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 115px;
    flex-grow: 0;
}

</style>
